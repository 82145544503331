import { Author } from "../index";
import { useEffect, useState } from "react";

export default function CardPopin(props) {
  const [show, setShow] = useState(false);

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div style={{ display: show ? "flex" : "none" }} className="popin-overlay">
      <div className="popin">
        <div
          style={{ backgroundColor: props.backgroundColor }}
          className="card-popin"
        >
          <p
            style={{
              color: props.color,
              fontFamily: props.font,
              fontSize: props.size,
            }}
          >
            {props.text}
          </p>
          <Author
            name={props.authorName}
            company={props.authorCompany}
            color={props.backgroundColor === "#ffffff" ? "#f11d58" : "#ffffff"}
          />
        </div>
        <div className="action-buttons">
          <button
            className="close"
            style={{
              color:
                props.backgroundColor === "#ffffff" ? "#000a21" : "#ffffff",
            }}
            onClick={closeHandler}
          >
            x
          </button>
          <button className="action-btn" onClick={closeHandler}>
            Je modifie
          </button>
          <button className="action-btn" onClick={props.handleSubmit}>
            Je valide
          </button>
        </div>
      </div>
    </div>
  );
}
