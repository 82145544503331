import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <a className="website-link" href="https://subskill.com" target="_blank">
                    <img src={logo} alt="" />
                    Visiter le site Subskill
                </a>
            </div>
        </footer>
    );
}
