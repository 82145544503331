import { Form, Header } from "../components";
import filler from "../assets/images/filler.jpg";

export default function Voeux({ setCards, setIsPosted }) {
  return (
    <>
      <Header />
      <main className="page-message">
        <div className="container">
          <div className="row">
            <h2 className="title col-lg-8 offset-lg-2">
              J’ajoute mes voeux <span>sur le mur de Subskill</span>
            </h2>
            <Form setCards={setCards} setIsPosted={setIsPosted} />
            <div className="filler-images" style={{backgroundImage: 'url(' + filler + ')'}}>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
