import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <header className="header">
      <Link to={"/"} className="img-container">
        <img src={logo} alt="" />
      </Link>
      <h1 className="title">
        Bienvenue sur le mur des voeux de <span>Subskill</span>
      </h1>
    </header>
  );
}
