import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Voeux } from "./pages";
import { useState } from "react";
import React from 'react';
import axios from 'axios';
import {Card} from "./components";

export default function App() {
  const [cards, setCards] = useState([]);
  const [isPosted, setIsPosted] = useState(false);


  return (
    <Router>
      <Routes>
        <Route
          path={"/"}
          element={<Home cards={cards} isPosted={isPosted} />}
        />
        {/* TODO: query param, si url?token=… préremplir le form */}
        <Route
          path={"/voeux"}
          element={<Voeux setCards={setCards} setIsPosted={setIsPosted} />}
        />
        <Route
          path={"*"}
          element={<Home cards={cards} isPosted={isPosted} />}
        />
      </Routes>
    </Router>
  );
}

