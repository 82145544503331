import { useState } from "react";
import { Card, CardPopin } from "../";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from 'react';
import axios from 'axios';

export default function Form({ setCards, setIsPosted }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    text: "",
    backgroundColor: "",
    font: "",
    color: "",
    size: "",
    authorName: "",
    authorCompany: "",
  });

  const [popinVisibility, setPopinVisibility] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
        return false;
    }
    return true;
  }

  // console.log(checkProperties(formData));
  let isEmptyForm = checkProperties(formData);
  if (token && isEmptyForm) {
    setFormData({
      text: "quidem videtur, inermis ac nudus est. tollit definitiones, nihil de dividendo ac partiendo docet, non quo modo efficiatur concludaturque ratio tradit, non qua via captiosaquidem videtur, inermis ac nudus est.",
      backgroundColor: "#233e7e",
      font: "FinancierDisplay-Regular",
      color: "#ffffff",
      size: "40px",
      authorName: "Mathilde",
      authorCompany: "Love and green",
    });
  }

  const popinCloseHandler = (e) => {
    setPopinVisibility(e);
  };

  const popinOpenHandler = (e) => {
    e.preventDefault();
    setPopinVisibility(!popinVisibility);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const cardItem = {
      Card: (
          <Card
              text={formData.text}
              backgroundColor={formData.backgroundColor}
              font={formData.font}
              color={formData.color}
              size={formData.size}
              authorName={formData.authorName}
              authorCompany={formData.authorCompany}
          />
      ),
    };

    const cardData = {
      "message": formData.text,
      "options": JSON.stringify({
        backgroundColor : formData.backgroundColor,
        font : formData.font,
        color : formData.color,
        size : formData.size,
        authorCompany : formData.authorCompany,
      }),
      "person": formData.authorName
    };

    const errors = [];
    const getOptions = cardData.options;

    if (!cardData.message) {
      errors.push("Merci de saisir un texte");
    }
    if (!cardData.person) {
      errors.push("Merci de saisir votre prénom");
    }
    if (!formData.size) {
      errors.push("Merci de choisir la taille de la police");
    }
    if (!formData.color) {
      errors.push("Merci de choisir la couleur");
    }
    if (!formData.font) {
      errors.push("Merci de choisir la police");
    }
    if (!formData.backgroundColor) {
      errors.push("Merci de choisir la couleur de la carte");
    }
    if (!formData.authorCompany) {
      errors.push("Merci de saisir votre entreprise");
    }

    if (errors.length) {
      errors.forEach(error => {
        console.log(error);
      });
    } else {
      axios.post(`https://carte-voeux-2023.alwaysdata.net/wish`,  cardData)
        .then(res => {
            setCards((prevArray) => [...prevArray, cardItem]);
            setIsPosted(true);
            setPopinVisibility(false);
            navigate("/");
        })
        .catch(err => {
            console.log(err);
        });
    }
  };

  return (
      <>
        <form className="form" onSubmit={popinOpenHandler}>
          <div className="form-author">
            <label>
              Qui je suis
              <input
                  type="text"
                  name="authorName"
                  value={formData.authorName}
                  onChange={handleChange}
                  required
              />
            </label>
            <label>
              Mon entreprise
              <input
                  type="text"
                  name="authorCompany"
                  value={formData.authorCompany}
                  onChange={handleChange}
                  required
              />
            </label>
          </div>
          <label className="form-message">
            Les voeux que je veux partager avec le monde entier
            <textarea
                name="text"
                value={formData.text}
                onChange={handleChange}
                required
                maxLength={200}
            />
          </label>
          <div className="form-configuration">
            <label className="card-color">
                Je choisis la couleur de ma card
              <div className="radio">
                <label>
                  <input
                      type="radio"
                      name="backgroundColor"
                      value="#ffffff"
                      onChange={handleChange}
                      checked={formData.backgroundColor === "#ffffff"}
                      required
                  />
                </label>
                <label>
                  <input
                      type="radio"
                      name="backgroundColor"
                      value="#f11d58"
                      onChange={handleChange}
                      checked={formData.backgroundColor === "#f11d58"}
                      required
                  />
                </label>
                <label>
                  <input
                      type="radio"
                      name="backgroundColor"
                      value="#233e7e"
                      onChange={handleChange}
                      checked={formData.backgroundColor === "#233e7e"}
                      required
                  />
                </label>
                <label>
                  <input
                      type="radio"
                      name="backgroundColor"
                      value="#000a21"
                      onChange={handleChange}
                      checked={formData.backgroundColor === "#000a21"}
                      required
                  />
                </label>
              </div>
            </label>
            <label className="card-font">
              Je choisis ma police
              <input
                  type="radio"
                  name="font"
                  value="FinancierDisplay-Regular"
                  id="FinancierDisplay-Regular"
                  onChange={handleChange}
                  checked={formData.font === "FinancierDisplay-Regular"}
                  required
              />
              <label htmlFor="FinancierDisplay-Regular">Mes voeux</label>
              <input
                  type="radio"
                  name="font"
                  value="OpenSans-Regular"
                  id="OpenSans-Regular"
                  onChange={handleChange}
                  checked={formData.font === "OpenSans-Regular"}
                  required
              />
              <label htmlFor="OpenSans-Regular">Mes voeux</label>
              <input
                  type="radio"
                  name="font"
                  value="FinancierDisplay-Black"
                  id="FinancierDisplay-Black"
                  onChange={handleChange}
                  checked={formData.font === "FinancierDisplay-Black"}
                  required
              />
              <label htmlFor="FinancierDisplay-Black">Mes voeux</label>
            </label>
            <label className="card-color">
              Je choisis la couleur de ma police
              <div className="radio">
                <label>
                  <input
                      type="radio"
                      name="color"
                      value="#ffffff"
                      onChange={handleChange}
                      checked={formData.color === "#ffffff"}
                      required
                  />
                </label>
                <label>
                  <input
                      type="radio"
                      name="color"
                      value="#f11d58"
                      onChange={handleChange}
                      checked={formData.color === "#f11d58"}
                      required
                  />
                </label>
                <label>
                  <input
                      type="radio"
                      name="color"
                      value="#000a21"
                      onChange={handleChange}
                      checked={formData.color === "#000a21"}
                      required
                  />
                </label>
              </div>
            </label>
            <label className="card-text-size">
              Je choisis la taille de ma police
              <input
                  type="radio"
                  name="size"
                  value="20px"
                  id="20px"
                  onChange={handleChange}
                  checked={formData.size === "20px"}
                  required
              />
              <label htmlFor="20px">20px</label>
              <input
                  type="radio"
                  name="size"
                  value="40px"
                  id="40px"
                  onChange={handleChange}
                  checked={formData.size === "40px"}
                  required
              />
              <label htmlFor="40px">40px</label>
              <input
                  type="radio"
                  name="size"
                  value="60px"
                  id="60px"
                  onChange={handleChange}
                  checked={formData.size === "60px"}
                  required
              />
              <label htmlFor="60px">60px</label>
            </label>
          </div>
          <button className="form-btn">J’ajoute mes voeux sur le mur</button>
        </form>
        <CardPopin
            text={formData.text}
            backgroundColor={formData.backgroundColor}
            font={formData.font}
            color={formData.color}
            size={formData.size}
            authorName={formData.authorName}
            authorCompany={formData.authorCompany}
            onClose={popinCloseHandler}
            handleSubmit={handleSubmit}
            show={popinVisibility}
        />
      </>
  );
}
