import { Author } from "../";

export default function Card(props) {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        border: `1px solid #000a21`
      }}
      className="masonry-card"
    >
      <p
        style={{
          color: props.color,
          fontFamily: props.font,
          fontSize: props.size,
        }}
      >
        {props.text}
      </p>
      <Author
        name={props.authorName}
        company={props.authorCompany}
        color={props.backgroundColor === "#ffffff" ? "#f11d58" : "#ffffff"}
      />
    </div>
  );
}
