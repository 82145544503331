export default function Author(props) {
  return (
    <div style={{ color: props.color }} className="author">
      <p>
        {props.name}
        {props.name && props.company ? " - " : ""}
        {props.company}
      </p>
    </div>
  );
}
