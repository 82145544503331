import { Link } from "react-router-dom";

export default function Button({ isPosted }) {
  return (
    <div className="buttons">
        <Link className="btn action-btn btn-wish" to="/voeux">
          J’ajoute mes voeux
        </Link>
        <a className="btn btn--posted action-btn" href="https://www.linkedin.com/shareArticle/?mini=true&url=https://carte-voeux-2023.subskill.com/" target='_blank'>
            Je partage le mur sur linkedin
        </a>
    </div>
  );
}
