import {Header, Button, Card} from "../components";
import {useState} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {Fragment} from "react";
import filler from "../assets/images/filler.jpg";
import React from 'react';
import axios from 'axios';
import Footer from "../components/Footer/Footer";
import {AnimationOnScroll} from 'react-animation-on-scroll';

export default function Home() {
    let [cards, setCards] = useState([]);
    let [isPosted] = useState(false);

    React.useEffect(() => {
        let newCards = []
        axios.get('https://carte-voeux-2023.alwaysdata.net/wishes').then((response) => {
            response.data.forEach(data => {
                let cardOptions = JSON.parse(data.options);
                let card = <Card
                    text={data.message}
                    backgroundColor={cardOptions.backgroundColor}
                    font={cardOptions.font}
                    color={cardOptions.color}
                    size={cardOptions.size}
                    authorName={data.person}
                    authorCompany={cardOptions.authorCompany}
                />;
                newCards.push(card);
            })
            setCards(newCards);
        });
    }, []);


    return (
        <>
            <Header/>
            <main className="page-home">
                <div className="page-header">
                    <h2 className="title">
                        Ensemble pour cette nouvelle <span>année 2023</span>
                    </h2>
                    <p className="text">
                        Les voeux ont plus de valeur quand ils sont partagés et vus par le plus
                        grand nombre
                    </p>
                    <Button isPosted={isPosted}/>
                </div>
                <ResponsiveMasonry
                    className="container"
                    columnsCountBreakPoints={{0: 1, 750: 2, 1200: 3}}
                >
                    <Masonry className="row gx-0">
                        {cards
                            .slice(0)
                            .reverse()
                            .map((Card, index) => {
                                if (index % 4 === 0 && index !== 0) {
                                    return (
                                        <>
                                            <Fragment key={index}>
                                                <img src={filler} alt=""/>
                                                {Card}
                                            </Fragment>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Fragment key={index}>{Card}</Fragment>
                                        </>
                                    );
                                }
                            })}
                    </Masonry>
                </ResponsiveMasonry>
            </main>
            <Footer/>
        </>
    );
}
